<template>
  <ion-modal class="modal-big" :is-open="isOpen" mode="md" :backdrop-dismiss="false" :show-backdrop="true">
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <div class="title">Select Image</div>
          <div />
          <div>
            <ion-buttons slot="end">
              <ion-button @click="close">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content">
      <div class="content flex-column">
        <div class="loading-overlay" :class="{ visible: isUploading }">
          <div class="d-flex inner-overlay">
            <div class="loading-indicator">
              <i class="ti-reload spin" />
            </div>
            <strong>Uploading...</strong>
          </div>
        </div>
        <div class="rounded-squares-container">
          <div class="rounded-square shaded d-flex align-items-center justify-content-center" @click="pressUpload">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <inline-svg src="/icons/upload-image.svg" class="mb-2 s" />
              <strong class="text-black" :class="[!!subscriptionLevel && 'mb-2']">Upload Image</strong>
              <div class="d-flex justify-content-center mt-1" v-if="!subscriptionLevel">
                <PlusTag size="md" />
              </div>
            </div>
          </div>
          <div class="rounded-square shaded d-flex align-items-center justify-content-center" @click="pressUploadGif">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <inline-svg src="/icons/gif.svg" class="mb-2 s" />
              <strong class="text-black class center-text" :class="[!!subscriptionLevel && 'mb-2']">Upload GIF</strong>
              <div
                class="d-flex justify-content-center mt-1"
                v-if="[subscriptionLevels.free, subscriptionLevels.plus].includes(subscriptionLevel)"
              >
                <PlusTag size="md" :ultra="true" />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2" v-if="backgroundImage.image">
          <div
            class="bg-cover-image"
            :style="{
              'background-image': `url(${backgroundImage.image})`,
            }"
          ></div>
          <div class="d-flex flex-column flex-wrap mx-2">
            <div class="sub-title">Image Display Mode:</div>
            <ion-radio-group v-model="backgroundImage.image_mode" class="d-flex flex-column" mode="md">
              <div class="d-flex align-items-center"><ion-radio value="scale" class="mr-1" />Scale Image</div>
              <div class="d-flex align-items-center"><ion-radio value="repeat" class="mr-1" />Repeat Image</div>
            </ion-radio-group>
          </div>
        </div>
      </div>
    </div>

    <ion-footer translucent v-if="backgroundImage.image">
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <ion-button class="try-btn" :disabled="isUploading" @click="action">Try On</ion-button>
          <ion-button class="save-btn" :disabled="isSubmitting || isUploading" @click="submit">Save</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
    <UploadForm
      id="modaluploader"
      ref="uploader"
      class="d-none"
      :allowGif="true"
      :restrictGif="true"
      @uploaded="handleChangeBackgroundImage"
      @loading="setUploading"
      @dismiss="dismissModal"
    />
  </ion-modal>
</template>

<script lang="ts" setup>
import UploadForm from '@/shared/components/upload-form.vue';
import { openSubscribeModal } from '@/shared/utils/modals';
import { authStore } from '@/shared/pinia-store/auth';
import PlusTag from '@/shared/components/PlusTag.vue';
import constants from '../statics/constants';
import { Character } from '../types/static-types';
import { characterStylizeStore } from '../pinia-store/character-stylize';
import { profileStore } from '../pinia-store/profile';

const { subscriptionLevel, updateProfileTryOnBgImage, user, updateProfileTryonImage, userTryonImage } = authStore();
const subscriptionLevels = constants.subscriptionLevels;
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  showSubPlanBadge: {
    type: Boolean,
    default: false,
  },
  character: {
    type: Object as PropType<Character>,
    default: {},
  },
});
const uploader = ref<UploadForm | null>(null);
const isSubmitting = ref(false);
const isUploading = ref(false);
const backgroundImage = ref({ image: '', image_mode: 'scale' });
const emits = defineEmits(['close']);
const { currentTemplate, changeCharacterCustomizationData } = characterStylizeStore();
const isOpen = toRef(props, 'isOpen');
const { isCustomizeProfile } = useCharPageColors();
const character = toRef(props, 'character');
const { postUserProfileBackgroundImage } = profileStore();
const close = () => {
  updateProfileTryonImage({});
  dismissModal();
};
const dismissModal = (bool = false) => {
  emits('close', bool);
};
watch(isOpen, (val) => {
  if (val) {
    const source = !isEmpty(userTryonImage.value)
      ? userTryonImage.value
      : !isEmpty(character.value) && isCustomizeProfile.value
      ? get(currentTemplate.value, 'data.background_image')
      : get(user.value, 'customize_profile.background_image');
    backgroundImage.value = cloneDeep(source) || { image: '', image_mode: 'scale' };
  }
});

const pressUpload = () => {
  if (subscriptionLevel.value === subscriptionLevels.ultra || subscriptionLevel.value === subscriptionLevels.plus) {
    nextTick(() => {
      if (uploader.value) {
        uploader.value?.getPhoto();
      }
    });
  } else {
    openSubscribeModal(null, 'imageUploadPlusModal');
  }
};
const setUploading = (val: boolean) => {
  isUploading.value = val;
};

const mode = computed(() => {
  return backgroundImage.value.image_mode === 'scale' ? 'no-repeat' : 'repeat';
});

const imageSize = computed(() => {
  return backgroundImage.value.image_mode === 'scale' ? 'cover' : '50px 50px';
});

const pressUploadGif = () => {
  if (subscriptionLevel.value === subscriptionLevels.ultra) {
    nextTick(() => {
      uploader.value?.getPhoto();
    });
  } else {
    openSubscribeModal(null, 'imageUploadPlusModal');
  }
};

const handleChangeBackgroundImage = async (dataIn: any) => {
  if (dataIn.target) return;
  if (!dataIn) return;
  backgroundImage.value.image = dataIn;
};

const submit = async () => {
  isSubmitting.value = true;
  if (!isEmpty(character.value)) {
    changeCharacterCustomizationData('background_image', backgroundImage.value);
  } else {
    postUserProfileBackgroundImage(backgroundImage.value);
  }
  isSubmitting.value = false;
  close();
};
const action = async () => {
  updateProfileTryonImage(backgroundImage.value);
  updateProfileTryOnBgImage(backgroundImage.value);

  setTimeout(() => {
    dismissModal(true);
  }, 50);
};
</script>

<style lang="sass" scoped>
.bg-cover-image
  background-size: v-bind(imageSize)
  background-repeat: v-bind(mode)
  background-position: center
  width: 100px
  height: 100px
  object-fit: cover
.try-btn
  --background: #33C3D1
  border-radius: 12px
.save-btn
  --background: #715BBB
  border-radius: 12px
ion-radio::part(container)
  width: 1rem !important
  height: 1rem !important
.content
  display: flex
  flex-direction: row
  justify-content: space-between
  padding: 13px

.rounded-squares-container
  width: 100%
  margin-left: auto
  margin-right: auto
  display: flex
  justify-content: space-between
  align-items: center
  gap: 0.5rem
  .rounded-square
    position: relative
    height: 130px !important
    width: 150px !important
    color: #214163
    border-radius: 15px
    padding: 15px, 0px, 15px, 0px
    div span
      user-select: none !important
    &:hover
      cursor: pointer !important
      opacity: 0.8
.shaded
  background: #EAECF6
.s
  width: 40px
  height: 40px
  background: white
  padding: 0.2rem
  border-radius: 8px
.dark
  .s
    background: #444
.dark .inner-overlay
  color: var(--ion-color-primary)
.loading-overlay
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.4)
  display: flex
  justify-content: center
  align-items: flex-start
  z-index: -1
  cursor: not-allowed
  border-radius: 20px
  opacity: 0
  transition: opacity 0.3s ease-in-out, z-index 0.05s step-end 0.08s
  &.visible
    opacity: 1
    z-index: 10
  .inner-overlay
    background: rgba(255, 255, 255, 1.0)
    padding: 1rem
    border-radius: 0.5rem
    display: flex
    position: relative
    align-items: center
    flex-direction: column
    top: 50%
    transform: translateY(-50%)
  .loading-indicator.modal-big
    animation: spin 3s linear infinite
    margin: 0.75rem
    i
      font-size: 2em
      color: #50c8ff !important
@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
.dark .toolbar
  --background: #17074c !important
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 89vh
  max-height: 89dvh

.modal-big
  --width: 320px
  --height: auto

.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
.sub-title
  color: #214163
  font-size: 14px
  font-weight: bold
.dark .sub-title
  color: white !important

.img
  width: 100px
  height: 100px
  object-fit: cover
</style>
